import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const NotFoundPage = () => (
    <Layout>
        <div className="home">
            <div className="home_background parallax-window" data-parallax="scroll" data-image-src="images/regular.jpg" data-speed="0.8"></div>
            <div className="home_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <article className="content" style={{ textAlign: `center` }}>
                                <h1 className="content-title">Error 404</h1>
                                <section className="content-body">
                                    No se ha encontrado la página, <Link to="/">Ve al inicio</Link> para comenzar de nuevo.
                                </section>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
